export const url='https://pakexplore.pk/api/'

export const provinceData={
    isb: {
        name: 'islamabad',
        area: 906.5,
        districts: 1
    },
    snd: {
        name: 'sindh',
        area: 140914,
        districts: 29
    },
    pjb: {
        name: 'punjab',
        area: 205344,
        districts: 36
    },
    kp: {
        name: 'khyber pakhtunkhwa',
        area: 101741,
        districts: 35
    },
    bln: {
        name: 'balochistan',
        area: 347190,
        districts: 26
    },
    gb: {
        name: 'gilgit baltistan',
        area: 72971,
        districts: 10
    },
    ajk: {
        name: 'azad jammu & kashmir',
        area: 13296,
        districts: 10
    }
}

export const gbDistrictData={
    hnz: {
        name: 'hunza',
    },
    dmr: {
        name: 'diamer',
    },
    gzr: {
        name: 'ghizer',
    },
    ngr: {
        name: 'nagar',
    },
    glt: {
        name: 'gilgit',
    },
    sku: {
        name: 'skardu',
    },
    sgr: {
        name: 'shigar',
    },
    ghe: {
        name: 'ghanche',
    },
    kmg: {
        name: 'kharmang',
    },
    ast: {
        name: 'astore'
    }
}

export const kpDistrictData={
    upc: {
        name: 'upper chitral'
    },
    lwc: {
        name: 'lower chitral'
    },
    upk: {
        name: 'upper kohistan'
    },
    swt: {
        name: 'swat'
    },
    upd: {
        name: 'upper dir'
    },
    lwd: {
        name: 'lower dir'
    },
    bnr: {
        name: 'buner'
    },
    mkd: {
        name: 'malakand'
    },
    bjr: {
        name: 'bajaur'
    },
    sgl: {
        name: 'shangla'
    },
    tgr: {
        name: 'thor ghar'
    },
    btg: {
        name: 'battagram'
    },
    msr: {
        name: 'mansehra'
    },
    abt: {
        name: 'abbottabad'
    },
    hpr: {
        name: 'haripur'
    },
    mdn: {
        name: 'mardan'
    },
    sbi: {
        name: 'swabi'
    },
    mhd: {
        name: 'mohmand'
    },
    chr: {
        name: 'charsada'
    },
    kbr: {
        name: 'khyber'
    },
    pwr: {
        name: 'peshawar'
    },
    nwr: {
        name: 'nowshera'
    },
    krm: {
        name: 'kurram'
    },
    okz: {
        name: 'orakzai'
    },
    kht: {
        name: 'kohat'
    },
    krk: {
        name: 'karak'
    },
    han: {
        name: 'hangu'
    },
    nrw: {
        name: 'north waziristan'
    },
    ban: {
        name: 'bannu'
    },
    lak: {
        name: 'lakki marwat'
    },
    swn: {
        name: 'south waziristan'
    },
    tan: {
        name: 'tank'
    },
    dik: {
        name: 'dera ismail khan'
    }
}

export const blnDistrictData={
    she: {
        name: 'sheerani'
    },
    zho: {
        name: 'zhob'
    },
    mus: {
        name: 'musakhel'
    },
    ksu: {
        name: 'killa saifullah'
    },
    bar: {
        name: 'barkhan'
    },
    zia: {
        name: 'ziarat'
    },
    lor: {
        name: 'loralai'
    },
    har: {
        name: 'harnai'
    },
    duk: {
        name: 'duki'
    },
    koh: {
        name: 'kohlu'
    },
    sib: {
        name: 'sibi'
    },
    kia: {
        name: 'killa abdullah'
    },
    mas: {
        name: 'mastung'
    },
    que: {
        name: 'quetta'
    },
    pis: {
        name: 'pishin'
    },
    deb: {
        name: 'dera bugti'
    },
    nas: {
        name: 'nasirabad'
    },
    jha: {
        name: 'jhal magsi'
    },
    kec: {
        name: 'kech'
    },
    gwa: {
        name: 'gwadar'
    },
    khu: {
        name: 'khuzdar'
    },
    las: {
        name: 'lasbela'
    },
    awa: {
        name: 'awaran'
    },
    pan: {
        name: 'panjgur'
    },
    soh: {
        name: 'sohbatpur'
    },
    jaf: {
        name: 'jaffarabad'
    },
    sur: {
        name: 'surab'
    },
    kal: {
        name: 'kalat'
    },
    nus: {
        name: 'nushki'
    },
    cha: {
        name: 'chagai'
    },
    kha: {
        name: 'kharan'
    },
    was: {
        name: 'washuk'
    },
    kac: {
        name: 'kacchi'
    }
}

export const ajkDistrictData={
    nee: {
        name: 'neelum'
    },
    muz: {
        name: 'muzaffarabad'
    },
    hat: {
        name: 'hattian'
    },
    hav: {
        name: 'haveli'
    },
    bag: {
        name: 'bagh'
    },
    poo: {
        name: 'poonch'
    },
    kot: {
        name: 'kotli'
    },
    sud: {
        name: 'sudhnuti'
    },
    mir: {
        name: 'mirpur'
    },
    bhi: {
        name: 'bhimber'
    }
}

export const pjbDistrictData={
    oka: {
        name: 'okara'
    },
    att: {
        name: 'attock'
    },
    mia: {
        name: 'mianwali'
    },
    rwp: {
        name: 'rawalpindi'
    },
    jhe: {
        name: 'jhelum'
    },
    khu: {
        name: 'khushab'
    },
    cha: {
        name: 'chakwal'
    },
    guj: {
        name: 'gujrat'
    },
    sar: {
        name: 'sargodha'
    },
    man: {
        name: 'mandi bahauddin'
    },
    sia: {
        name: 'sialkot'
    },
    guw: {
        name: 'gujranwala'
    },
    haf: {
        name: 'hafizabad'
    },
    nar: {
        name: 'narowal'
    },
    she: {
        name: 'sheikhupura'
    },
    lhr: {
        name: 'lahore'
    },
    dgk: {
        name: 'dera ghazi khan'
    },
    bah: {
        name: 'bahawalpur'
    },
    kas: {
        name: 'kasur'
    },
    nan: {
        name: 'nankana sahib'
    },
    fai: {
        name: 'faisalabad'
    },
    chi: {
        name: 'chiniot'
    },
    bha: {
        name: 'bhakkar'
    },
    lay: {
        name: 'layyah'
    },
    jha: {
        name: 'jhang'
    },
    bnr: {
        name: 'bahawalnagar'
    },
    pak: {
        name: 'pakpattan'
    },
    tts: {
        name: 'toba tek singh'
    },
    sah: {
        name: 'sahiwal'
    },
    kha: {
        name: 'khanewal'
    },
    lod: {
        name: 'lodhran'
    },
    veh: {
        name: 'vehari'
    },
    mul: {
        name: 'multan'
    },
    ryk: {
        name: 'rahim yar khan'
    },
    raj: {
        name: 'rajanpur'
    },
    muz: {
        name: 'muzaffargarh'
    }
}

export const sndDistrictData={
    jac: {
        name: 'jacobabad'
    },
    shi: {
        name: 'shikarpur'
    },
    suk: {
        name: 'sukkur'
    },
    qam: {
        name: 'qambar'
    },
    lar: {
        name: 'larkana'
    },
    kha: {
        name: 'khairpur'
    },
    dad: {
        name: 'dadu'
    },
    naf: {
        name: 'Naushahro Feroze'
    },
    ben: {
        name: 'benazirabad'
    },
    jam: {
        name: 'jamshoro'
    },
    kar: {
        name: 'karachi'
    },
    san: {
        name: 'sanghar'
    },
    mat: {
        name: 'matiari'
    },
    tan: {
        name: 'tando allahyar'
    },
    tmk: {
        name: 'tando muhammad khan'
    },
    bad: {
        name: 'badin'
    },
    kas: {
        name: 'kashmore'
    },
    gho: {
        name: 'ghotki'
    },
    hyd: {
        name: 'hyderabad'
    },
    tha: {
        name: 'tharparkar'
    },
    mik: {
        name: 'mirpur khas'
    },
    ume: {
        name: 'umerkot'
    },
    suj: {
        name: 'sujawal'
    },
    tta: {
        name: 'thatta'
    }
}


export const kpADistricts={"upper chitral":"upc","lower chitral":"lwc","upper kohistan":"upk","swat":"swt","upper dir":"upd","lower dir":"lwd","buner":"bnr","malakand":"mkd","bajaur":"bjr","shangla":"sgl","thor ghar":"tgr","battagram":"btg","mansehra":"msr","abbottabad":"abt","haripur":"hpr","mardan":"mdn","swabi":"sbi","mohmand":"mhd","charsada":"chr","khyber":"kbr","peshawar":"pwr","nowshera":"nwr","kurram":"krm","orakzai":"okz","kohat":"kht","karak":"krk","hangu":"han","north waziristan":"nrw","bannu":"ban","lakki marwat":"lak","south waziristan":"swn","tank":"tan","dera ismail khan":"dik"}
export const gbADistricts={"hunza":"hnz","diamer":"dmr","ghizer":"gzr","nagar":"ngr","gilgit":"glt","skardu":"sku","shigar":"sgr","ghanche":"ghe","kharmang":"kmg","astore":"ast"}
export const ajkADistricts={"neelum":"nee","muzaffarabad":"muz","hattian":"hat","haveli":"hav","bagh":"bag","poonch":"poo","kotli":"kot","sudhnuti":"sud","mirpur":"mir","bhimber":"bhi"}
export const pjbADistricts={"okara":"oka","attock":"att","mianwali":"mia","rawalpindi":"rwp","jhelum":"jhe","khushab":"khu","chakwal":"cha","gujrat":"guj","sargodha":"sar","mandi bahauddin":"man","sialkot":"sia","gujranwala":"guw","hafizabad":"haf","narowal":"nar","sheikhupura":"she","lahore":"lhr","dera ghazi khan":"dgk","bahawalpur":"bah","kasur":"kas","nankana sahib":"nan","faisalabad":"fai","chiniot":"chi","bhakkar":"bha","layyah":"lay","jhang":"jha","bahawalnagar":"bnr","pakpattan":"pak","toba tek singh":"tts","sahiwal":"sah","khanewal":"kha","lodhran":"lod","vehari":"veh","multan":"mul","rahim yar khan":"ryk","rajanpur":"raj","muzaffargarh":"muz"}
export const sndADistricts={"jacobabad":"jac","shikarpur":"shi","sukkur":"suk","qambar":"qam","larkana":"lar","khairpur":"kha","dadu":"dad","Naushahro Feroze":"naf","benazirabad":"ben","jamshoro":"jam","karachi":"kar","sanghar":"san","matiari":"mat","tando allahyar":"tan","tando muhammad khan":"tmk","badin":"bad","kashmore":"kas","ghotki":"gho","hyderabad":"hyd","tharparkar":"tha","mirpur khas":"mik","umerkot":"ume","sujawal":"suj","thatta":"tta"}
export const blnADistricts={"sheerani":"she","zhob":"zho","musakhel":"mus","killa saifullah":"ksu","barkhan":"bar","ziarat":"zia","loralai":"lor","harnai":"har","duki":"duk","kohlu":"koh","sibi":"sib","killa abdullah":"kia","mastung":"mas","quetta":"que","pishin":"pis","dera bugti":"deb","nasirabad":"nas","jhal magsi":"jha","kech":"kec","gwadar":"gwa","khuzdar":"khu","lasbela":"las","awaran":"awa","panjgur":"pan","sohbatpur":"soh","jaffarabad":"jaf","surab":"sur","kalat":"kal","nushki":"nus","chagai":"cha","kharan":"kha","washuk":"was","kacchi":"kac"}

export const provinceAName={
    islamabad: 'isb',
    sindh: 'snd',
    punjab: 'pjb',
    'khyber pakhtunkhwa': 'kp',
    balochistan: 'bln',
    'gilgit baltistan': 'gb',
    'azad jammu & kashmir': 'ajk'
}
