import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import {ReactComponent as Logo} from '../images/logo-black.svg'
//import MovingMessage from './movingMessage';
import SearchBar from './SearchBar';
import './HomePageHeader.css'
import { url } from '../dataFiles/data.jsx';
import axios from 'axios'
import { FiSearch } from "react-icons/fi"
import {kpADistricts,sndADistricts,pjbADistricts,gbADistricts,ajkADistricts,blnADistricts} from '../dataFiles/data.jsx';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PulseLoader from "react-spinners/PulseLoader"

export default function HomePageHeader(props){
    const navigate=useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [showSearch, setShowSearch] = useState(false)
    const [searchLoading,setSearchLoading]=useState(false)
    const [enableSearch,setEnableSearch]=useState(false)

    useEffect(() => {
        AOS.init()
    }, [])

    const styles={
        head:{
            fontSize: 'large',
            fontWeight: 'bold',
            height: '76px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        hr: {
            color: '#ebecf0',
        },
        container: {
            maxWidth: '1000px',
            margin: 'auto',
            lineHeight: '50px'
        },
        logo: {
            height: '56px',
            marginLeft: '4px',
            cursor: 'pointer'
        },
        searchButton: {
            cursor: 'pointer',
            fontSize: '1.5rem',
            marginLeft: 'auto'
        }
    }

    const handleSearch = async (searchTerm) => {
        if(searchTerm.length>3){
            try{
                setSearchLoading(true)
                setEnableSearch(true)
                const result=await axios.post(url+'search',{searchTerm})
                setSearchLoading(false)
                setSearchResults(result.data)
            }catch(err){
                console.log(err)
            }
        }else{
            setSearchResults([])
            setEnableSearch(false)
        }
    };

    const navigateToPage=(result)=>{
        let furl=''
        switch(result.province){
            case 'islamabad': furl=url+'isb/isb/'+result.title
            break
            case 'khyber pakhtunkhwa': furl='/kp/'+kpADistricts[result.district]+'/'+result.title
            break
            case 'gilgit baltistan': furl='/gb/'+gbADistricts[result.district]+'/'+result.title
            break
            case 'azad jammu & kashmir': furl='/ajk/'+ajkADistricts[result.district]+'/'+result.title
            break
            case 'balochistan': furl='/bln/'+blnADistricts[result.district]+'/'+result.title
            break
            case 'sindh': furl='/snd/'+sndADistricts[result.district]+'/'+result.title
            break
            case 'punjab': furl='/pjb/'+pjbADistricts[result.district]+'/'+result.title
            break
            default:
            break
        }
        navigate(furl)
    }
    const handleShowSearch = ()=>{setShowSearch(true)}
     return (
        <div>
            <div className='header'>
                <div className='header-head'>
                    <div>
                        <Logo onClick={()=>{
                            navigate('/')
                        }} style={styles.logo} />
                    </div>
                    <div style={styles.searchButton} onClick={()=>{setShowSearch(true)}}><FiSearch/></div>
                    <div>
                        {props.devMode && <p className='header-button' onClick={props.handleLogout} >Logout DevMode</p>}
                    </div>
                </div>
            </div>
            {/* {!props.plain && <MovingMessage message={
                    'We are thrilled to announce the launch of our website! Our website is your go-to destination for exploring the beautiful regions of Pakistan.'
                    + ' At present, we are focusing on Gilgit-Baltistan, one of the most beautiful regions in the world.'
                    +' Our website is constantly being updated with new and informative content to help you explore Pakistan and learn more about its rich culture, history, and natural beauty.'
                    + ' So, please feel free to browse our site and check back often for new updates and information.'
            }/>} */}
            {!props.plain && showSearch && <div data-aos='zoom-in' className='searchbar'>
                <div className='search-result'>
                    <SearchBar onSearch={handleSearch} handleShowSearch={handleShowSearch}/>
                    <div className='search-close' onClick={()=>{setShowSearch(false)}}>X</div>
                    {searchLoading?<PulseLoader/>:searchResults.length===0 && enableSearch?<div>No results found</div>:searchResults.map((result, index) => (
                    <div key={index}>
                        <div onClick={()=>{navigateToPage(result)}} className='search-li'>
                            <img className='search-picture' src={result.titlePicture}/>
                            <h3 className='search-title'>{result.title}</h3>
                        </div>
                        <hr></hr>
                    </div>
                    ))}
                </div>
                {searchResults.length>0 && showSearch && <div onClick={()=>{setShowSearch(false)}} className='search-overlay-background'></div>}
            </div>}
        </div>
    )
}