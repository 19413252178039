import {useState,useEffect} from 'react'
import PakMap from './images/PakSatMapMini.jsx';
import ProvinceInfo from './components/ProvinceInfo.jsx'
import './App.css'
import HomePageHeader from './components/HomePageHeader.jsx';
import {useNavigate} from 'react-router-dom'
import Footer from './components/Footer.jsx';
import {isMobile} from 'react-device-detect'
import { url } from './dataFiles/data.jsx';
import axios from 'axios'
import PopularLocationSlider from './components/PopularLocations.jsx';

function App() {
  const devMode=false
  const [username,setUsername]=useState('');
  const [password,setPassword]=useState('');
  const [isLoggedIn,setLogin]=useState(false);
  const [passwordFailure,setWrongPassword]=useState(false);
  const [isLoading,setLoading]=useState(false);
  const [name,setName]=useState('gb');
  const [locationsData,setLocationsData]=useState([]);
  const [initialEvent,setInitialEvent]=useState(false);
  const [showBottomDiv,setShowBottomDiv]=useState(window.innerWidth<650)
  const navigate=useNavigate();
  const updateShowBottomDiv=()=>{
    setShowBottomDiv(window.innerWidth<650)
  }

  const setTokenHeader=(token)=>{
    if(token){
        axios.defaults.headers.common['Authorization']=`Bearer ${token}`;
    }else{
        delete axios.defaults.headers.common['Authorization'];
    }
  }

  const switchProvince=()=>{
    switch(name){
      case 'isb': setName('gb')
        break 
      case 'gb': setName('kp')
        break 
      case 'kp': setName('bln')
        break 
      case 'bln': setName('snd')
        break 
      case 'snd': setName('pjb')
        break 
      case 'pjb': setName('ajk')
        break 
      case 'ajk': setName('isb')
        break 
      default:
        break
    }
  }
  
  useEffect(()=>{
    if(localStorage.devToken){
      try{
          setTokenHeader(localStorage.devToken);
          if(verifyUser())
            setLogin(true)
      }catch(er){
          console.log(er)
      }
    }
    fetchPopularLocations()
    let switchTimer=setTimeout(()=>{
      if(!initialEvent){
        switchProvince();
      }
    },2000)
    window.addEventListener('resize',updateShowBottomDiv);
    return ()=>{
      window.removeEventListener('resize',updateShowBottomDiv)
      clearTimeout(switchTimer);
    }
  },[name,initialEvent])

  const verifyUser=async ()=>{
    try{
      const verify=await axios.get(url+'verifydev')
      return verify.data['validate']
    }catch(err){
      console.log(err)
    }
  }

  async function fetchPopularLocations(){
    try{
      const data=await axios.get(url+'popularlocations')
      setLocationsData(data.data)
    }catch(er){
      console.log(er)
    }
  }

  const handleLoginSubmit=async (e)=>{
    setLoading(true)
    e.preventDefault();
    try{
        const signInObj=await axios.post(url+'devsignin',{username,password})
        const {token} = signInObj.data
        localStorage.setItem('devToken', token)
        setTokenHeader(token)
        setLogin(true)
        setUsername('')
        setPassword('')
        setLoading(false)
    }catch(err){
        console.log(err)
        setWrongPassword(true)
        setLoading(false)
    }
  }

  function handleMouseEnter(e){
    e.preventDefault()
    setName(e.target.id);
    if(!initialEvent){
      setInitialEvent(true)
    }
  }
  function handleMouseLeave(e){
    e.preventDefault()
    //setName('')
    setInitialEvent(false)
  }

  function handleProvinceClick(e){
    e.preventDefault()
    if(name==='isb')
      navigate('/isb/isb');
    else
      navigate('/'+name);
  }

  const handleLogout=()=>{
    localStorage.removeItem('devToken')
    setTokenHeader(null)
    setLogin(false)
  }

  const loginForm = (
    <div className='admin-main'>
        <div className='login-page'>
            <div className='login-form'>
                <form onSubmit={()=>{}}>
                    <h2>PAKEXPLORE</h2>
                    <h2>Developer Login</h2>
                    <input type="text" name='username' value={username} onChange={(e)=>{
                      setUsername(e.target.value)
                      setWrongPassword(false)
                      }} placeholder="username"/>
                    <input type="password" name='password' value={password} onChange={(e)=>{
                      setPassword(e.target.value)
                      setWrongPassword(false)
                      }} placeholder="password"/>
                    <button onClick={handleLoginSubmit} type='submit'>login</button>
                    {isLoading && <div>loading</div>}
                    {passwordFailure && <div className='password-failure-message'>wrong username or password</div>}
                </form>
            </div>
        </div>
    </div>
  )

  const page=(
    <>
      <HomePageHeader devMode={devMode} handleLogout={handleLogout}/>
      <div className='main-container'>
        <div className='province-info-place'>
          <ProvinceInfo name={name}/>
        </div>
        {!showBottomDiv && <div className='side-text-container'>
          <div className='hero-text'>explore beautiful tourist destinations in pakistan with PAKEXPLORE</div>
        </div>}
        <div className='map-place'>
          <PakMap name={name} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} handleProvinceClick={isMobile?handleMouseEnter:handleProvinceClick}/>
        </div>
        {name!=='' && isMobile && <button onClick={handleProvinceClick} className='button'>Next</button>}
        {showBottomDiv && <div className='bottom-text-container'>
          <div className='hero-text'>explore beautiful tourist destinations in pakistan with PAKEXPLORE</div>
        </div>}
        {locationsData.length>0 && <PopularLocationSlider locationsData={locationsData}/>}
      </div>
      <Footer/>
    </>
  )
  return devMode?isLoggedIn?page:loginForm:page
}

export default App;