import React from 'react';
import HomePageHeader from './HomePageHeader';
import MapComponent from './MapComponent';
import SpotDescription from './SpotDescription';
import './spotPage.css'
import YoutubeComponent from './YTComponent';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';
import {useParams} from "react-router-dom";
import axios from 'axios';
import CryptoJS from 'crypto-js'
import HashLoader from "react-spinners/HashLoader"
import { url } from '../dataFiles/data';
import {isMobile} from 'react-device-detect'
import ReactImgCarousel from './react-img-carousel';


function SpotPage(){
    const [des,setDes]=React.useState({})
    const [loading,setLoading]=React.useState(true)
    const {province,district,title}=useParams()
    const furl=url

    const fetchData=async ()=>{
        const url=furl+province+'/'+district+'/'+title
        try{
            setLoading(true)
            const data = await axios.get(url)
            setLoading(false)
            setDes(JSON.parse(CryptoJS.AES.decrypt(data.data,'c23Sctrgyh').toString(CryptoJS.enc.Utf8))[0])
        }catch(err){
            console.log('sometine went wrong')
        }
    }

    const override: CSSProperties = {
        display: "block",
        margin: "150px auto",
        borderColor: "red",
    };

    React.useEffect(() => {
        AOS.init()
        fetchData()
    }, [])
    const styles={
        mapStyle: {
            border: '2px solid darkturquoise',
            width: '33%',
            marginRight: '10px',
            height: '99%',
        },
        streetviewStyle: {
            border: '2px solid darkturquoise',
            width: '65%',
            marginRight: '10px',
            height: '99%',
        },
        addMidStyle: {
            width: '15%',
            height: '100%',
        },
    }
    //const { des } = useLocation().state;
    const pictures=loading?[]:des.pictures.map(pic=>{
        return {src: pic}
    })
    return (
        <>
            <HomePageHeader />
            {loading?<HashLoader cssOverride={override}/>:
            <><div className='main-container'>
                <div data-aos='zoom-in'>
                    {/* <CarouselComponent height={'600px'} data={pictures}/> */}
                    <ReactImgCarousel data={pictures} title={des.title}/>
                </div>
                <div className='details-container'>
                    <SpotDescription description={des.description} title={des.title}
                        stayOptions={des.stayOptions} locationAccess={des.locationAccess} activities={des.activities} />
                </div>
                <div data-aos="fade-up" data-aos-duration='1000' data-aos-anchor-placement='center-bottom' className='map-container'>
                    <MapComponent mapview={true} styling={styles.mapStyle} lat={des.latitude} lon={des.longitude}/>
                    {des.displayStreetview && <MapComponent styling={styles.streetviewStyle} streetViewSrc={des.streetViewSrc}/>}
                </div>
                {des.youtubeEmbed &&<div data-aos="fade-up" data-aos-duration='1000' data-aos-anchor-placement='center-bottom' className='youtube-container'>
                    <YoutubeComponent frame={des.youtubeEmbed}/>
                </div>}
            </div>
            <Footer/></>}
        </>
    )
}

export default SpotPage;